@import '/src/scss/shared';

.root {
    margin-right: 8px;
    width: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 4px;
    padding-right: 8px;
}