@import '/src/scss/shared';

.header {
    background-color: $secondary; 
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    height: 54px;
    width: 100%;
    padding: 0 24px;

    .cnt {
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: 100%;
    }

    .link {
        display: flex;
        color: darken($onSecondary, 15%);
        font-weight: bold;
        text-transform: uppercase;
        font-size: 1.3rem;
        line-height: 1.3rem;
        cursor: pointer;
        &:hover {
            color: $onSecondary;
        }
        @media (max-width: 640px) {
            display: none;
        }
        &.visible {
            @media (max-width: 640px) {
                display: flex;
            }
        }
        a {
            font-size: 1.3rem;
            line-height: 1.3rem;
            color:darken($onSecondary, 15%);
            text-transform: uppercase;
            font-weight: bold;
            &:hover {
                color: $onSecondary
            }
        }
    }
}