@import '/src/scss/shared';

.rounds {
    margin-top: 8px;
    @media screen and (max-width: 640px) {
        overflow: hidden;
        &.moreEnabled {
            height: 170px;
        }
    }
    height: auto;
    overflow: auto;
}

.coach {
    border: 1px solid $primary;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 6px;
}

.logo {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 100%;
    margin-right: 8px;
}

.person {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.2;
}

.userName {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.3rem;
    font-size: 1.3rem;
    @media (max-width: 640px) {
        width: 176px;
    }    
}

.circle {
    border: 2px solid $primary;
    padding: 8px;
    border-radius: 100%;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.circleItem {
    font-weight: bold;
    font-size: 1.3rem;
    line-height: 1.3rem;
}

.circleCnt {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: bold;
}

.favTeam {
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.caption {
    width: auto;
    .icon {
        font-size: 24px;
        padding-right: 4px;
    
    }
}


.info {
    margin-right: 8px;
    padding: 8px;
}


.title {
    margin-bottom: 0;
    border-bottom: 4px solid $primary;
    color: $onPrimary;
    padding: 4px 8px;
    text-transform: uppercase;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.itemCnt {
    display: flex;
    border-bottom: 1px solid #eee;
    justify-content: space-between;
}

.itemActive {
    color: #969696;
    i {
        pointer-events: none;
    }
}

.listItem {
    padding: 4px 8px 4px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    text-transform: uppercase;
    cursor: pointer;
    width: calc(100% - 59px);
    &:last-child {
        border-bottom: 0;
    }
    &:hover {
        background-color: #eee;
    }
}

.person {
    display: flex;
    align-items: center;
    margin-left: 8px;
    width: 220px;
}

.flexItem {
    display: flex;
    align-items: center;
    font-size: 1.3rem;
    line-height: 1.3rem;
}

.caption {
    font-size: 1.3rem;
    font-weight: bold;
    line-height: 1.3rem;
}

.meIcon {
    color: $primary;
    position: relative;
    left: 8px
}

.allLink {
    text-transform: uppercase;
    font-size: 16px;
    padding: 0;
}

.active {
    background-color: $primary;
}

.standingsCnt {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px 0 0;
    .standingsBtn {
        font-size: 16px;
        cursor: pointer;
        margin-left: 8px;
        &:hover {
            color: $primary;
        }
    }
}

.showMore {
    display: none;
    @media screen and (max-width: 640px) {
        margin-top: 4px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        text-transform: uppercase;
        font-size: 12px;
    }
}

