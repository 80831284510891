@import '/src/scss/shared';

.panel {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: normal;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 48px;

    .col {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin-right: 8px;
        width: 33.3%;
    }
}



.caption {
    font-size: 1rem;
    line-height: 1rem;
}

.badge {
    font-size: 1.3rem;
    line-height: 1.3rem;
    width: 100%;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @media screen and (max-width: 640px) {
        font-size: 1.3rem;
    }

    @media (min-width: 769px) and (max-width: 1082px)  {
        font-size: 1.3rem;
    }

    &.left {
        justify-content: flex-start;
    }

    &.hoverable:hover {
        background-color: $primary !important;
        color: $onPrimary !important;
        cursor: pointer;

        .points {
            border-color: $primary;
        }
    }
    .points {
        position: absolute;
        right: 0;
        background-color: $primary;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $onPrimary;
        padding: 0 8px;
        border-radius: 0 6px 6px 0;
        width: 40%;
    }
}

.success {
    color: $primary;
    font-weight: bold;
}

.error {
    color: $danger;
    font-weight: bold;
}

.diff {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
    font-size: 0.8rem;
    line-height: 0.8rem;
}

.labelCnt {
    display: flex;
    align-items: center;
    justify-content: center;
}

.liveBadge {
    color: $onSecondary;
    display: flex;
    justify-content: space-between;
}

.transfersBadge {
    display: flex; 
    justify-content: space-between;
    color: $onSecondary;
}

.lightTextBadge {
    color: $onSecondary;
}