@import '/src/scss/shared';

.cnt {
    width: 100%;

    .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 8px 0;

        .col {
            width: 30%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}

.textInput {
    width: 100%; 
    height: 38px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0 10px;
    color: #333;
    font-size: 1.3rem;
}

.dropdown {
    width: 100%;
    font-size: 1.3rem;
}

.trashCnt {
    display: flex;
    justify-content: flex-end;
}

.remove {
    color: $danger;
    font-size: 1.3rem;
    text-transform: uppercase;
    cursor: pointer;
    &:hover {
        font-weight: bold;
    }
}