@import '/src/scss/shared';
.title {
    margin-bottom: 0;
    margin-top: 4px;
    border-bottom: 4px solid $primary;
    color: $black;
    padding: 0 8px;
    text-transform: uppercase;
    font-size: 1.3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.listItem {
    padding: 4px 8px;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    text-transform: uppercase;
    cursor: pointer;
    &:last-child {
        border-bottom: 0;
    }
    &:hover {
        background-color: #eee;
    }
}

.rank {
    font-size: 1.2rem;
    line-height: 1.2rem;
    min-width: 42px;
}

.logo {
    width: 35px;
    height: 35px;
    border-radius: 100%;
    margin-right: 8px;
    object-fit: cover;
}

.person {
    display: flex;
    align-items: center;
    margin-left: 8px;
    width: 220px;
}

.flexItem {
    display: flex;
    align-items: center;
    flex: 0 1 39px;
    font-size: 1.3rem;
}

.caption {
    font-size: 1.3rem;
    line-height: 1.3rem;
}

.meIcon {
    color: $primary;
    position: relative;
    left: 8px
}

.allLink {
    text-transform: uppercase;
    font-size: 1.3rem;
    padding: 0;
}