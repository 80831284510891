@import '/src/scss/shared';
.event {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 36px;
    margin-right: 8px;

    .wrapper {
        width: 260px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .description {
        color: $onPrimary;
        font-size: 1rem;
        line-height: 1rem;
        text-transform: uppercase;
    }

    .ownGoal {
        position: relative;
        left: -41px;
        color: $onSecondary;
        font-size: 10px;
    }

    &.end {
        justify-content: flex-end;
        .penalty {
            position: absolute;
            right: 8px;
            left: auto;
            color: $onPrimary;
            font-size: 10px;
            
        }
        @media (max-width: 640px) {
            justify-content: flex-start;
        }
    }

    .col {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
    }

    .leftCol {
        margin-right: 16px;
        width: 45px;
        font-size: 1.3rem;
    }

    .players {
        display: flex;
        flex-direction: column;
        margin-left: 0;

        .name {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 152px;
            font-size: 1.3rem;
        }
    }

    .row {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .teamName {
        text-transform: uppercase;
        font-size: 13px;
        font-weight: bold;
    }

    .grey {
        color: #898989;
    }
}

