@import '/src/scss/shared';

.bgGradient {
    background: $primary;
    @media only screen and (max-device-width: 375px) {
        background: $primary;
    }
    padding: 0;
    text-align: center;
    color: $onSecondary;
    height: 100vh; 
    @media screen and (max-width: 640px) {
        height: calc(100vh - 80px);
    }
    overflow: hidden;
    position: relative;
}

.head {
    color: $onSecondary;
    font-weight: 300;
    letter-spacing: -1px;
    margin-bottom: 1rem;
    @media screen and (max-width: 767px) {
        font-size: 32px;
    }
}

.tagline {
    text-align: center;
    color: $onSecondary;
    font-size: 1.7rem;
    line-height: 2rem;
    font-weight: 300;
    max-width: 800px;
    margin: 0 auto;
    @media screen and (max-width: 767px) {
        font-size: 1.5rem;
        line-height: 1.7rem;
    }
    @media screen and (max-width: 640px) {
        text-align: left;
    }
}

.padCnt {
    position: absolute;
    z-index: 12;
    background-color: $landingBgColor;
    width: 100%;
    bottom: 0;
    padding: 24px 32px;
    @media only screen and (min-device-width: 480px) 
                   and (max-device-width: 1000px) 
                   and (orientation: landscape) {
        top: 0
    }
    @media only screen and (max-width: 375px) {
        padding: 16px 32px;
    }
    @media screen and (max-width: 640px) {
        height: 336px;
    }
}

.imgHolder {
    position: absolute;
    top: 54px;
    bottom: 280px;
    width: 100%;
    left: 0;
    right: 0;
    @media screen and (max-width: 375px) {
        top: 0;
        transform: scale(0.75);
    }
    @media screen and (min-width: 376px) and (max-width: 640px) {
        bottom: 330px;
    }
}

.img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.actions {
    margin-top: 1rem;
    display: flex;
    align-items: baseline;
    width: 100%;
    justify-content: center;
    flex-direction: row;
}

.loginBtn, .ruleBtn {
    font-size: 1.5rem;
    line-height: 1.5rem;
    width: 180px;
    text-transform: uppercase;
    transition: all 0.2s;
    &:hover {
        transform: scale(1.1);
    }
}

@keyframes blink {
    0% {
      background-color: $primary;
    }

    100% {
      background-color: darken($primary, 25%);
      border-color: darken($primary, 25%);
    }
  }

.loginBtn {
    animation-name: blink;
    animation-timing-function: ease-in-out;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

.ruleBtn {
    width: 210px;
    margin-right: 24px;
}

.logo {
    height: 64px;
    @media screen and (max-width: 640px) {
        height: auto;
    }
    max-width: 100%;
}
