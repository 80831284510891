@import '/src/scss/shared';

.submitCnt {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $secondary;
    padding: 8px;
    height: 52px;
    border-top: 1px solid $white;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;

    &.pwa {
        height: 82px;
    }

    .submitPanel {
        width: 100%;
        max-width: 1280px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .warning {
            color: $onSecondary;
            font-weight: bold;
            font-size: 1.3rem;
            text-transform: uppercase;
            padding: 0 8px 0 0;
            line-height: 1.2rem;
            display: flex;
            align-items: center;
        }
    }
}

.buttonsCnt {
    display: flex;
    height: 36px;
}

.submit {
    text-transform: uppercase;
    font-weight: bold;
    min-width: 140px;
    font-size: 1.3rem;
    line-height: 1.3rem;
}

.undo {
    min-width: auto;
    margin-right: 8px;
    font-size: 1.3rem;
    line-height: 1.3rem;
    text-transform: uppercase;
    font-weight: bold;
}

.dangerBg {
    background-color: $danger;
}

