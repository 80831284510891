@import '/src/scss/shared';

.fixture {
    &.admin {
        height: 70vh;
        overflow: auto;
    }
}

.cnt {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.headRow {
    display: flex;
    align-items: center;
    font-weight: bold;
}

.subTitle {
    font-size: 1.3rem;
    text-transform: uppercase;
    font-weight: bold;
    margin: 8px 0;
}

.events {
    width: 100%;
    margin-top: 8px;
}

.home, .away {
    width: 50%;
}

.team {
    text-transform: uppercase;
    width: 154px;
    font-size: 1.3rem;
}

.add {
    margin-top: 8px;
    color: green;
    font-size: 1.3rem;
    text-transform: uppercase;
    cursor: pointer;
    &:hover {
        font-weight: bold;
    }
}
