@import '/src/scss/shared';

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .icon {
        font-size: 24px;
        margin: 0 16px;
        cursor: pointer;
        &:hover {
            color: $primary;
        }
    }
    
    .logo {
        height: 100px;
        user-select: none;
        object-fit: contain;
    }

    .gift {
        position: absolute;
        position: absolute;
        bottom: -38px;
        font-size: 20px;
        color: $onPrimary;
        border-radius: 100%;
        display: inline-flex;
        width: 30px;
        height: 36px;
        align-items: center;
        justify-content: center;
        padding: 8px;
    }
}

.actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 42px 0 16px 0;

    .btn {
        text-transform: uppercase;
        font-size: 1.3rem;
    }
}

.selectItem {
    display: flex;
    align-items: center;

    .logo {
        height: 30px; 
        object-fit: contain;
        filter: sepia(1);
    }

    .caption {
        margin-left: 8px;
    }
}

.dropdown {
    font-size: 1.3rem;
    line-height: 1.3rem;
}

