@import '/src/scss/shared';

.photoCnt {
    display: flex;
    align-items: center;

    .name {
        line-height: 1.3rem;
        font-size: 1.3rem;
        font-weight: bold;
    }

    .hint {
        display: flex;
        font-size: 1.3rem;
        line-height: 1.3rem;
    }

    .age {
        margin-left: 8px;
        color: #848484;

    }

    .photo {
        height: 80px;
        border-radius: 100%;
        object-fit: contain;
    }

    .info {
        font-size: 16px;
        text-transform: uppercase;
        height: 80px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-left: 8px;
    }

    .pts {
        border: 2px solid $primary;
        padding: 12px;
        border-radius: 100%;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 12px;
        align-self: baseline;
        position: relative;
        top: 6px;
        font-size: 18px;
        color: $onPrimary;
    }

    .cost {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 12px;
        align-self: baseline;
        position: relative;
        top: 6px;
        font-size: 18px;
        color: $onPrimary;
    }
}

.listContainer {
    display: flex;
    overflow-y: scroll;
    .listItem {
        display: flex;
        flex-direction: column;
        border: 1px solid darken($primary, 15%);
        margin-right: 8px;
        min-width: 80px;
        height: 96px;
        padding: 0 8px;
        border-radius: 4px;
        cursor: pointer;
        background-color: darken($primary, 15%);

        .round {
            font-weight: bold;
            font-size: 16px;
            color: $onPrimary;
        }

        &:hover{
            background-color: $primary;
        }

        &.selected {
            background-color: $primary;
        }
    }
}

.detailsContainer {
    padding: 8px 0;

    .detailsItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px;
        border-top: 1px solid #eee;

        &.thick {
            border-bottom: 1px solid #adb5bd;
        }

        &:first-child{
            border-top: 0;
        }

        .key {
            font-size: 1.3rem;
            line-height: 1.3rem;
            text-transform: uppercase;
            padding: 0 40px 0 0;
        }

        .value {
            font-size: 1.3rem;
            line-height: 1.3rem;
            font-weight: bold;
            text-transform: uppercase;
        }
    }
}



.panel {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 48px;
    border-top: 1px solid #eee;
    padding-top: 12px;
    margin-top: 8px;

    .col {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin-right: 8px;
        width: 33.3%;
    }
}



.caption {
    display: flex;
    font-size: 1rem;
    line-height: 1rem;
}

.badge {
    font-size: 1.2rem;
    line-height: 1.2rem;
    width: 100%;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.hoverable:hover {
        background-color: $primary !important;
        color: $onPrimary !important;
        cursor: pointer;
    }
}

.msgCnt {
    margin: 16px 0;
}

.rivalCnt {
    display: flex;
    margin-top: 8px;    
}

.rivalLogo {
    object-fit: contain;
    width: 25px;
    height: 25px;
    margin-right: 8px;
    filter: sepia(1);
}

.lightTextBadge {
    color: $onSecondary;
}

.darkTextBadge {
    color: $onPrimary;
}

.ptsBadge {
    border: 1px solid $white; 
    color: $onPrimary; 
    font-size: 1.3rem;
    padding: 2px 8px;
}
