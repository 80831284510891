@import '/src/scss/shared';

h2 {
  margin: 0;
  text-align: left;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}

.hamburger {
  height: 46px;
  align-items: center;
  position: relative;
  top: -2px;
  cursor: pointer;
  i {
    color: $white;
  }
  i:hover {
    color: $primary;
  }
  @media (min-width: 640px) {
    display: none;
  }
}

.dropdown {
  display: none;
  @media (min-width: 640px) {
    display: block;
  }
}

.header {
  background-color: $secondary;
  color: $primary;
  box-sizing: border-box;
  height: 54px;
  border-bottom: 1px solid $secondary;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 999;
}

.logo {
  width: 200px;
  cursor: pointer;
}

.avatar {
  height: 25px;
}

.headerCnt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 40px;

  .beta {
    position: absolute;
    top: 0;
    left: 205px;
    background-color: #bf1e2c;
    padding: 0 8px;
    color: $white;
    font-size: 12px;
    font-weight: bold;
}}


.white {
  font-size: 1.3rem;
  line-height: 1.3rem;
  text-transform: uppercase;
  text-decoration: none;
  color: $onSecondary;
  &:hover {
    color: $primary;
  }
}

.rightSide {
  display: flex;
  align-items: center;
}

.partnerCnt {
  display: flex;
  flex-direction: row;
  @media (max-width: 640px) {
    flex-direction: column;
  }

  .plogo {
    height: 25px;
    margin-right: 8px;
    cursor: pointer;
  }
  .pcaption {
    position: absolute;
    top: -8px;
    font-size: 0.75rem;
    text-transform: uppercase;
    color: $white;
    @media (min-width: 640px) {
      position: static;
      font-size: 1rem;
      margin-right: 8px;
    }
  }
}

.menu {
  max-height: none !important;
}
