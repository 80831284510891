@import "/src/scss/shared";
@import "~bootstrap/scss/bootstrap";

.modal {
  --bs-modal-width: 550px;
}

.badge {
  --bs-badge-padding-x: 0.4em;
}

body {
  margin: 0;
  font-family: "Alumni Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
  height: 100vh;
  background-color: $white;
  text-underline-offset: 4px;
  -webkit-font-smoothing: antialiased;
}

button,
li {
  user-select: none;
}

p {
  font-size: 1.3rem;
  line-height: 1.6rem;
}

a {
  color: darken($primary, 15%);
  text-decoration: none;
  &:hover {
    color: $primary;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.alert-hidden {
  bottom: -100px;
}

.alert-visible {
  bottom: 60px;
  @media (max-width: 640px) {
    bottom: 56px;
  }
}

.alert {
  transition: 0.3s bottom;
}

.logo {
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid gray;
  border-radius: 100%;
  width: 50px;
  height: 50px;

  & img {
    object-fit: contain;
    height: 40px;
    width: 40px;
  }
}

.login-tiles {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0;
  .tile-icon {
    width: 40px;
    height: 40px;
  }
  .tile {
    list-style: none;
    text-transform: uppercase;
    border: 1px solid $primary;
    width: 120px;
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: space-around;
    padding: 8px;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    margin: 8px 0;
    &:hover {
      background-color: $primary;
      color: $onPrimary;
      border-color: $primary;
    }
  }
}

.login-hint {
  font-size: 0.9rem;
  line-height: 1.1rem;
  color: #808080;

}

.form-switch .form-check-input {
  width: 2em;
  height: 2em;
  cursor: pointer;
  position: relative;
  top: -2px;
}
.dropdown-toggle {
  font-size: 1.3rem;
  line-height: 1.3rem;
  text-transform: uppercase;
  font-weight: bold;
  color: $onSecondary;
  &:disabled {
    color: $onSecondary;
  }
}

.dropdown-item {
  font-size: 1.3rem;
  text-transform: uppercase;
  padding: 4px 22px;
}

.scrollToTop {
  position: fixed;
  bottom: 12px;
  right: 12px;
  opacity: 0.85;
  border: 1px solid $white;
  &.pwa {
    bottom: 16px;
  }
}

.home {
  .scrollToTop {
    bottom: 58px;
    &.pwa {
      bottom: 88px;
    }
  }
}

.modal-header .btn-close {
  position: absolute;
  top: 16px;
  right: 16px;
}

@media (max-width: 1024px) {
  .fav-team-column {
    display: none !important;
  }
}

.qc-cmp2-persistent-link {
  display: none !important;
}

.blink-me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.player-widget-enter {
  opacity: 0;
  transform: scale(0.9);
}
.player-widget-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.player-widget-exit {
  opacity: 1;
}
.player-widget-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.home .ptr__pull-down {
  top: 54px;
}

.page-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.private-league-cnt {
  position: relative;
  top: -4px;
  width: 100%;
  padding: 8px 8px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1280px;
  margin: 0 auto;
  border-top: 1px solid $primary;

  .private-league-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
  }

  .private-league-title-text {
    max-width: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    @media screen and (max-width: 640px) {
      max-width: 104px;
    }
  }

  .private-league-dropdown {
    padding: 4px 16px;
    height: 31px;
    display: flex;
    align-items: center;
    color: $onPrimary;
  }

  .private-league-actions {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 0;
  }

  .private-league-filter-toggle {
    width: 135px;
    height: 31px;
    padding: 0;
  }

  .private-league-filter {
    margin-right: 8px;
  }
}

.icon {
  transition: all 0.2s;
  display: flex;
}
.icon:hover {
  transform: rotate(180deg) scale(1.2);
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.private-league-spinner {
  margin-left: 8px;
  color: $primary;
  height: 25px;
  width: 25px;
}

.new-rule {
  color: darkred;
}

.lightText {
  color: $onSecondary;
}

.darkText {
  color: $onPrimary;
}

.btn-primary {
  color: $onPrimary;
  &:hover {
    color: $onPrimary;
  }
  &:disabled {
    color: $onPrimary;
  }
}

.disabled {
  button::after {
    display: none;
  }
  pointer-events: none;
}

.btn-link {
  color: $secondary;
  &:hover {
    color: darken($secondary, 10%);
  }
}

.bg-success {
  color: $onSecondary;
}

.bg-danger {
  color: $white;
}

.ml-8 {
  margin-left: 8px;
}

.mr-8 {
  margin-right: 8px;
}

body {
  padding: 0;
  margin: 0;
}
main-loader {
  height: 100vh;
  background-color: transparent;
}

.main-fader {
  width: 100%;
  position: absolute;
  background-color: transparent;
  .loader {
    font-size: 1.5rem;
    line-height: 1.5rem;
    width: 280px;
    text-transform: uppercase;
    text-align: center;
    font-weight: normal;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    svg {
      height: 250px;
      display: block;
      margin: 0 auto;
      path {
        animation-duration: 1s;
        animation-name: pulse;
        animation-iteration-count: infinite;
        color: $secondary;
        fill: $primary;

        &.path-7 {
          animation-delay: -1s;
        }
        &.path-6 {
          animation-delay: -0.875s;
        }
        &.path-5 {
          animation-delay: -0.75s;
        }
        &.path-4 {
          animation-delay: -0.625s;
        }
        &.path-3 {
          animation-delay: -0.5s;
        }
        &.path-2 {
          animation-delay: -0.375s;
        }
        &.path-1 {
          animation-delay: -0.25s;
        }
        &.path-0 {
          animation-delay: -0.125s;
        }
      }
    }
  }
}

@keyframes pulse {
  0% {
    opacity: 0.1;
  }
  30% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.1;
  }
}

.flex {
  display: flex;
}

.page-bar {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1;
  top: 54px;
  background-color: $barColor;
  box-shadow: 1px 1px 6px $dark;
  .inner {
    height: 54px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1280px;
    margin: 0 auto;

    .bar-title {
      font-size: 1.3rem;
      line-height: 1.3rem;
      font-weight: bold;
      margin: 0 0 0 8px;
      text-transform: uppercase;
    }
  }
}

.page-offset {
  margin-top: 58px;
}

.round-matches-cnt {
  min-height: 488px;
  @media screen and (max-width: 767px) {
    min-height: auto;
  }
}

.rules-table td:first-child {
  text-align: left;
}

.rules-table tr {
  text-align: center;
}

.dropdown-menu.show {
  max-height: 286px;
  overflow: auto;
}

#CookiebotWidget {
  display: none;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  color: $secondary;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}

.row.react-bootstrap-table-pagination {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}

#pageDropDown {
  display: none;
}

.react-bootstrap-table-pagination {
  width: 100%;
  justify-content: flex-end;
  div {
    &:nth-child(1) {
      display: none;
    }
    &:nth-child(2) {
      align-items: flex-end;
      max-width: 100%;
    }
  }
}

.react-bootstrap-table-page-btns-ul {
  justify-content: flex-end;
  .active {
    .page-link {
    background-color: $secondary;
    color: $white;
    }
  }
  .page-link {
    font-size: 1.3rem;
    background-color: $primary;
    color: $black;
    &:focus {
      box-shadow: none;
    }
  }
}

.nav-link {
  color: $secondary;
  font-size: 1.2rem;
  text-transform: uppercase;
}

.sr-only {
  font-size: 0.9rem;
  line-height: 0.9rem;
  text-transform: uppercase;
}
