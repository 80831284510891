@import "/src/scss/shared";

.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 24px 0;

  .btn {
    text-transform: uppercase;
    font-size: 1.3rem;
  }
}

.item {
  border: 1px solid $dark;
  padding: 4px 8px;
  margin-bottom: 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.3rem;
  line-height: 1.3rem;

  .innerCnt {
    display: flex;
    align-items: center;

    .box {
      width: 40px;
      height: 40px;
      background-color: $primary;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
    }

    .photo {
      width: 45px;
      height: 45px;
      object-fit: contain;
      border-radius: 100%;
      border: 1px solid #eee;
      margin-right: 8px;
    }

    .info {
      font-size: 1.1rem;
      text-transform: uppercase;

      .lastname {
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @media (max-width: 376px) {
          max-width: 102px;
        }
      }
    }
  }
}

.arrowBtn {
  border: 1px solid #eee;
  padding: 8px 10px;
  background-color: #eee;
  color: $black;
  font-size: 1.5rem;
  margin-left: 8px;
}

@media (hover: hover) and (pointer: fine) {
  .arrowBtn:hover {
    border: 1px solid $secondary;
    color: $secondary;
  }
}
