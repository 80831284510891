@import '/src/scss/shared';

.actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 24px 0;

    .btn {
        text-transform: uppercase;
        font-size: 1.3rem;
    }
}

