@import '/src/scss/shared';

h5 {
    margin-bottom: 0;
    border-bottom: 4px solid $primary;
    color: $black;
    padding: 4px 8px;
    text-transform: uppercase;
    font-size: 1.3rem;
    line-height: 1.3rem;
    margin-top: 8px;
}

.action {
    width: 100%;
    margin-bottom: 8px;
    text-transform: uppercase;
    font-weight: bold;
}

.icon {
    font-size: 24px;
}

.submit {
    text-transform: uppercase;
    font-weight: bold;
}

.btnContent {
    display: flex;
    font-size: 1.3rem;
    line-height: 1.3rem;
    font-weight: 400;
}

.photoCnt {
    display: flex;
    align-items: center;

    .name {
        line-height: 1.3rem;
        font-size: 1.3rem;
        font-weight: bold;
    }

    .photo {
        height: 80px;
        border-radius: 100%;
        object-fit: contain;
    }

    .info {
        font-size: 16px;
        text-transform: uppercase;
        height: 80px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-left: 8px;
    }
}

.titleRow {
    height: 31px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 2px 8px 1px 8px;
    span {
        display: flex;
    }
}

.contrast {
    background-color: $primary;
    border-bottom: 0;
    padding: 4px 8px;
}

.innerTitle {
    background-color: $primary;
    border-bottom: 0;
}

.rightWrapper {
    display: flex;
    .chatTile {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        i {
            color: $primary; 
            position: relative;
            top: 2px;
        }
        .chatCaption {
            margin-right: 4px;
        }
        &:hover i {
            color: $dark;
        }
    }
}

.reserveItems {
    display: flex;
    flex-direction: column;
}

.bprBtn {
    text-transform: uppercase;
    padding-top: 0;
    padding-bottom: 0;
    font-weight: bold;
}

.bprIcon:before {
    position: relative;
    top: 1px;
}

.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.substHint {
    font-size: 0.8rem;
    line-height: 0.8rem;
    color: #777;
}