@import '/src/scss/shared';

.actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 8px 0;

    .btn {
        text-transform: uppercase;
        font-size: 1.3rem;
    }   
}

.hint {
    color: green;
}

