@import '/src/scss/shared';
.dropdown {
    width: 100%;
    font-size: 1.3rem;
}

.actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 16px 0 16px 0;

    button {
        text-transform: uppercase;
        font-size: 1.3rem;
    }
}

.error {
    color: $danger;
    font-weight: bold;
}