@import '/src/scss/shared';

.dropdown {
    color: $onSecondary;
    top: -2px;
    margin-left: 12px;
}

.toggle {
    padding: 4px 16px;
    height: 24px;
    display: flex;
    align-items: center;
    width: 80px;
}