@import "/src/scss/shared";

.coach {
  border: 1px solid $primary;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 6px;
}

.logo {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  margin-right: 8px;
}

.person {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.2;
  position: relative;
  cursor: pointer;
  padding: 0 8px;
  border-radius: 4px;
  margin-left: 4px;

  &:hover {
    background-color: #eee;
  }
}

.userName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 380px) {
    width: 96px;
  }
  font-size: 1.3rem;
  line-height: 1.3rem;
}

.caption {
  width: auto;
  .icon {
    font-size: 24px;
    padding-right: 8px;
  }
}

.toggleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  .toggle {
    cursor: pointer;
    rotate: 180deg;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hint {
    position: relative;
    text-transform: uppercase;
    line-height: 0.6rem;
    font-size: 0.75rem;
    height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
  }
}

.info {
  margin-right: 8px;
  padding: 8px;
  font-size: 28px;
  cursor: pointer;
  &:hover {
    color: $primary;
  }
}

.teamImg {
  width: 35px;
  height: 35px;
  object-fit: contain;
  filter: sepia(1);
}

.favCnt {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $primary;
  padding: 4px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: $white;
    outline: 1px solid $primary;
  }

  &:hover .favCaption {
    color: $onPrimary;
  }

  &:hover .qst {
    color: $secondary;
  }

  .qst {
    color: $onSecondary;
    height: 35px;
    font-size: 24px;
  }

  .favCaption {
    font-size: 1rem;
    width: 51px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1.1;
    text-align: center;
    margin-bottom: 2px;
    color: $black;
  }
}

.leftCnt {
  display: flex;
}

.disabled {
  pointer-events: none;
}

.successStatus {
  background-color: white;
  border-radius: 100%;
  padding: 1px;
  position: absolute;
  right: 8px;
  bottom: -2px;
  font-size: 16px;
  color: green;
}

.errorStatus {
  background-color: white;
  border-radius: 100%;
  padding: 1px;
  position: absolute;
  right: 8px;
  bottom: -2px;
  font-size: 16px;
  color: red;
}

.rightCnt {
  display: flex;
  align-items: center;
  justify-content: center;
}

.revertCnt {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    .caption {
        line-height: 0.6rem;
        font-size: 0.75rem;
        text-align: center;
        text-transform: uppercase;
        position: relative;
        top: -2px;
    }

    .revertBtn {
        border: 1px solid #eee;
        background-color: #eee;
        border-radius: 4px;
        color: $black;
        width: 2.3em;
        height: 2em;
        &:hover {
            border-color: $secondary;
            color: $secondary;
        }
    }
}
