@import '/src/scss/shared';
.title {
    margin-bottom: 0;
    border-bottom: 4px solid $primary;
    color: $black;
    padding: 4px 4px 2px 4px;
    text-transform: uppercase;
    font-size: 1.3rem;
    line-height: 1.3rem;
}

.listItem {
    padding: 7px 8px 8px 0;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    text-transform: uppercase;
    &:last-child {
        border-bottom: 0;
    }
}

.details {
    margin-left: 8px;
    display: flex;
}

.detailsItem {
    flex: 1 0 110px;
    text-align: center;
    display: flex;
    align-items: center;

}

.feed {
    align-self: center;
    cursor: pointer;
    height: 20px;
    
}

.feedCnt {
    height: 20px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.feedLayer {
    width: 50px;
    height: 25px;
    background-color: rgb(255 255 255 / 0%);
    position: absolute;
    cursor: pointer;
    &:hover {
        background-color: rgb(255 255 255 / 30%);
    }
}

.score {
    display: flex;
}

.cnt {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.rightCnt {
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.matchId {
    font-size: 1.3rem;
    line-height: 1.3rem;
    width: 65px; 
    color: #0dcaf0;
    margin-right: 8px;
    display: flex;
    align-items: center;
    @media (max-width: 640px) {
        display: none;
    }
}

.teamLogoLeft {
    object-fit: contain;
    height: 30px;
    width: 25px;
    background-color: $white;
    margin-right: 4px;
    filter: sepia(1);
}

.teamLogoRight {
    object-fit: contain;
    height: 30px;
    width: 25px;
    background-color: $white;
    margin-left: 4px;
    filter: sepia(1);
}

.deadlineBadge {
    font-size: 1.2rem;
    line-height: 1.2rem;
    width: 90px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.scoreBadge {
    color: $onPrimary;
    width: 35px;
    pointer-events: none;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.clickable {
    pointer-events: all;
    cursor: pointer;
    &:hover {
        color: $white;
        background-color: $danger !important;
    }
}

.shortName {
    display: none;
    @media (max-width: 1024px) {
        display: block;
    }
}

.fullName {
    display: block;
    @media (max-width: 1024px) {
        display: none;
    }
}

.leftTeamCnt {
    width: 152px;
    font-size: 1.3rem;
    @media (max-width: 1024px) {
        width: 74px;
        font-size: 1.3rem;
    }
}

.rightTeamCnt {
    margin-left: 12px;
    width: 152px;
    font-size: 1.3rem;
    justify-content: flex-end;
    @media (max-width: 1024px) {
        width: 64px;
        font-size: 1.3rem;
        justify-content: space-between;
    }
}

.roundPicker {
    user-select: none;
    display: flex;
    width: 85px;
    position: relative;
    padding: 0 16px;
    justify-content: center;
    .caption {
        display: flex;
    }
    i {
        position: absolute;
        cursor: pointer;
        &:hover {
            color: $danger;
        }
    }
    
}