@import '/src/scss/shared';

.canvas {
    height: calc(100vh - 54px) !important;
    background: linear-gradient($primary, $white);

    .header {
        position: absolute;
        right: 0;
    }

    .body {
        padding: 0 8px;
    }
}

.menuItem {
    cursor: pointer;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin-left: -8px;
    margin-right: -8px;
    padding: 8px;
    font-size: 20px;
    color: $black;

    &:hover {
        background-color: $secondary;
        color: $onSecondary;
    }

    i {
        font-size: 24px;
    }
}