@import '/src/scss/shared';

.photoCnt {
    display: flex;
    align-items: center;

    .name {
        line-height: 1.3rem;
        font-weight: bold;
    }

    .photo {
        height: 80px;
        border-radius: 100%;
    }

    .info {
        font-size: 1.3rem;
        line-height: 1.3rem;
        text-transform: uppercase;
        height: 80px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-left: 8px;
    }

    .pts {
        border: 2px solid $primary;
        padding: 12px;
        border-radius: 100%;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 12px;
        align-self: baseline;
        position: relative;
        top: 6px;
        font-size: 1.3rem;
        line-height: 1.3rem;
        color: $onPrimary;
    }
}

.listContainer {
    display: flex;
    overflow-y: scroll;
    .listItem {
        display: flex;
        flex-direction: column;
        border: 1px solid $primary;
        margin-right: 8px;
        min-width: 80px;
        padding: 0 8px;
        border-radius: 4px;
        cursor: pointer;


        .round {
            font-weight: bold;
            font-size: 16px;
        }

        &:hover{
            background-color: $primary;
        }
    }
}

.rivalLogo {
    object-fit: contain;
    width: 25px;
    height: 25px;
    margin-right: 8px;
    filter: sepia(1);
}

.detailsContainer {
    padding: 0;

    .detailsItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px;
        border-bottom: 1px solid #eee;

        &:first-child {
            padding: 0 8px 8px;
        }

        &.thick{
            border-bottom: 1px solid #adb5bd;
        }

        .key {
            font-size: 1.3rem;
            line-height: 1.3rem;
            text-transform: uppercase;
            padding: 0 16px 0 0;
        }

        .value {
            font-size: 1.3rem;
            line-height: 1.3rem;
            font-weight: bold;
            text-transform: uppercase;
        }
    }
}

.selected {
    background-color: $primary;
}