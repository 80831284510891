@import '/src/scss/shared';

.caption {
    font-size: 1rem;
    line-height: 1rem;
}

.badge {
    font-size: 1.3rem;
    line-height: 1.3rem;
    width: 100%;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &.left {
        justify-content: flex-start;
        border-radius: 0;
    }

    &.hoverable:hover {
        background-color: $primary !important;
        color: $onPrimary !important;
        cursor: pointer;

        .rightSideText {
            border-color: $primary;
        }
    }
    .rightSide {
        position: absolute;
        right: 0;
        background-color: $primary;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $onPrimary;
        padding: 0 8px;
        border-radius: 0;
        width: 50px;
    }
}