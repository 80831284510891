@import '/src/scss/shared';
.header {
    text-transform: uppercase;
    background-color: #eee;
    padding: 8px;
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.row {
    margin: 8px 0;
    align-items: center;
}

.col {
    margin-bottom: 0;
    @media (max-width: 640px) {
        margin-bottom: 8px;
    }
    button {
        width: 100%;
    }
}

.player {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 0 8px;
    height: 38px;
}

.last {
    color: red;
}

.info {
    font-size: 22px;
    margin-right: 8px;
    cursor: pointer;
    padding: 8px;
    &:hover {
        color: $primary;
    }

}

.textFldCnt {
    display: flex;
    justify-content: space-between;
}

.dropdownContainer {
    display: flex;
}

.miniExclamation {
    font-size: 1rem;
    color: $danger;
    bottom: -4px;
    right: 6px;
    position: absolute;
}