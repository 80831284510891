.actions {
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
    font-size: 1.3rem;
    @media (max-width: 640px) {
        flex-direction: column;
        .leftPanel {
            margin-bottom: 12px;
        }
    }

}

.summary {
    justify-content: space-between;
    color: green;
    margin-top: 12px;
}

.toggle {
    margin-bottom: 8px;
    margin-left: 8px;
    position: relative;
    top: 4px;
}
