@import '/src/scss/shared';

.inactive {
    filter: grayscale(80%)
}

.footballFieldCnt {
    margin-top: 8px;
}

.field {
    background-color: $primary;
    margin-top: 8px;
    height: 448px;
    position: relative;
    overflow: hidden;
    padding: 8px;
    &.loading {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    @media (max-width: 480px) {
        margin-bottom: 6px;
    }
}

.square {
    top: 0;
    position: absolute;
    border: 4px solid $white;
    height: 86px;
    width: 50%;
    left: 25%;
    border-top: 0;
}

.circle {
    height: 50%;
    width: 50%;
    left: 25%;
    border-radius: 100%;
    bottom: -130px;
    position: absolute;
    border: 4px solid $white;

    @media (max-width: 640px) {
        height: 50%;
        width: 50%;
    }
    
}

.corner {
    border: 4px solid $white;
    height: 30px;
    width: 30px;
    border-radius: 100%;
    position: absolute;
    top: -15px;
}

@keyframes spin { 
    100% { 
        transform:rotate(360deg); 
    } 
}

.item {
    display: flex;
    flex-direction: column;
    padding: 4px;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border-radius: 4px;

    &:last-child {
        border-bottom: 0;
    }
    
    &:hover {
        background-color: $white;
    }

    .bio {
        display: flex;
        position: relative;
        font-size: 12px;
        align-items: center;
        flex-direction: column;
        width: 96px;
        height: 92px;
        justify-content: center;

        @media (max-width: 480px) {
            width: 60px;
        }

        @media (min-width: 480px) and (max-width: 768px) {
            width: 79px;
        }

        @media (min-width: 769px) and (max-width: 1082px)  {
            width: 60px;
        }

        .photo {
            object-fit: contain;
            width: 45px;
            height: 45px;
            border-radius: 100%;
            text-align: center;
        }

        .logo {
            width: 22px;
            position: absolute;
            right: 0;
            @media  screen and (max-width: 1082px) {
                right: -8px;
            }
            top: -2px;
            filter: sepia(1);
        }

        .cost {
            font-size: 1rem;
            line-height: 1rem;
            color: $onPrimary;
            padding: 2px 8px;
            @media (max-width: 1082px) {
                display: none;
            }
        }

        .pts {
            font-size: 1rem;
            line-height: 1rem;
            padding: 2px 8px;
        }

        .coin {
            position: relative;
            top: 1px;
        }

        .lastname {
            font-weight: normal;
            text-transform: uppercase;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 1rem;
            line-height: 1rem;
            margin-bottom: 2px;
            @media (max-width: 640px) {
                font-size: 0.9rem;
            }
        }



        .info {
            position: relative;
            background-color: $white;
            padding: 4px;
            margin-top: 4px;
            border-radius: 4px;
            width: 100%;
            text-align: center;
            font-size: 13px;
            height: 50px;
            display: flex;
            flex-direction: column;

            .hint {
                text-transform: uppercase;
                display: flex;
                align-items: center;
                justify-content: space-around;
            }
        }

        .icon {
            color: $secondary;
            &.rotate {
                transform: rotate(0deg);
                animation: spin 3s linear infinite;
            }
        }

        .position {
            position: absolute;
            top: -57px;
            left: -3px;
            @media (max-width: 640px) {
                left: -15px;
            }

        }
    }
}

.blur {
    .column {
        filter: blur(4px);
    }
}

.clickDisabled {
    opacity: 0.2;
    pointer-events: none;
}

.toggled {
    background-color: lighten($primary, 30%);
    &.item:hover .info {
        background-color: $white !important;
    }
    .info {
        background-color: lighten($primary, 30%) !important;
        &:hover {
            background-color: $white !important;
        }
    }
}

.icon {
    font-size: 1.5rem;
    color: $secondary;
    color: $onSecondary;
}

.cap {
    position: absolute;
    width: 24px;
    top: 27px;
    padding: 0;
    font-size: 1rem;
    line-height: 1rem;
    @media  screen and (max-width: 1082px) {
        right: -8px;
    }
    right: 0;
    color: $white;
}

.tablo {
    font-size: 1.3rem;
    line-height: 1.3rem;
    position: absolute;
    left: 0px;
    top: -23px;
    background-color: $white;
    border-radius: 100%;
    display: inline-flex;
    font-weight: bold;
    width: 20px;
    height: 20px;
    align-items: center;
    justify-content: center;
    border: 1px solid $white;
}

.column {
  flex: 0 1 40px;
  position: relative;
}

.caption {
    position: absolute;
    top: -42px;
    width: 67px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    left: -19px;
    text-align: center;
}

.goalkeeper, .defenders, .midfielders, .attackers, .reserved {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
    margin-bottom: 8px;
}

.reserved {
    margin-top: 8px;
    height: 145px;
    .item {
        outline: 1px solid #eee;
        &:hover {
            outline: 1px solid $primary;
        }
    }

    .bio {
        height: 105px;
    }
    
    .pos {
        font-size: 1rem;
        line-height: 1rem;
        color: $onPrimary;
        margin-top: 8px;
    }
}

.outCnt {
    display: flex;
    flex-direction: column;
}

.toggleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    .toggle {
        cursor: pointer;
    }

    .hint {
        position: relative;
        text-transform: uppercase;
        font-size: 10px;
    }
}

.autoreplace {
    position: absolute;
    top: -46px;
    color: $danger;
    background-color: $white;
    border-radius: 100%;
    display: flex;
    font-size: 16px;
    left: 2px;
    &.up {
        color: green;
    }
    &.down {
        color: $danger;
    }
}

.exclamation {
    font-size: 26px;
}

.miniExclamation {
    font-size: 1rem;
    line-height: 1rem;
    color: $danger;
}

.shortPos {
    color: $onPrimary;
    font-size: 1rem;
    line-height: 1rem; 
}

.playerBg {
    border-radius: 100%;
}

.playerBgTransparent {
    background-color: transparent;
}

.playerBgColored {
    background-color: $white;
}

.bprBtn {
    text-transform: uppercase;
    padding-top: 0;
    padding-bottom: 0;
    font-weight: bold;
    position: absolute;
    right: 8px;
}

.bprIcon:before {
    position: relative;
    top: 1px;
}

.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1px;
}

.substHint {
    font-size: 0.8rem;
    line-height: 0.8rem;
    color: #777;
}