@import '/src/scss/shared';
.title {
    margin-bottom: 0;
    margin-top: -3px;
    border-bottom: 4px solid $primary;
    color: $black;
    padding: 0 8px;
    text-transform: uppercase;
    font-size: 1.3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.listItem {
    font-size: 1.3rem;
    padding: 5px 4px 8px;
    border-bottom: 1px solid #eee;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
    cursor: pointer;
    &:last-child {
        border-bottom: 0;
    }
    &:hover {
        background-color: #eee;
    }
}

.rank {
    font-size: 1rem;
    line-height: 1rem;
    width: 100%;
    color: $onPrimary;
    margin-top: 7px;
}

.logo {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    object-fit: cover;
}

.person {
    display: flex;
    align-items: center;
    flex-direction: column;
    border: 1px solid #eee;
    border-radius: 4px;
    width: 100%;
    padding: 4px 0 5px;
}

.flexItem {
    display: flex;
    align-items: center;
    flex: 0 1 39px;
    font-size: 1.3rem;
}

.caption {
    font-size: 1rem;
    line-height: 1rem;
    text-align: center;
    margin-top: 4px;
    margin-bottom: 4px;
    min-height: 36px;
    display: flex;
    align-items: center;
}

.meIcon {
    color: $primary;
    position: absolute;
    left: 12px;
    top: 7px;
}

.allLink {
    text-transform: uppercase;
    font-size: 1.3rem;
    padding: 0;
}

.favTeam {
    position: absolute;
    top: 8px;
    right: 14px;
    display: flex;
    flex-direction: column;
}