@import '/src/scss/shared';

@mixin cnt {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
}

@mixin badge {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    color: $onPrimary;
}

.homeCnt {
    @include cnt;
    justify-content: flex-start;
}

.awayCnt {
    @include cnt;
    justify-content: flex-end;
}

.badge {
    @include badge;
    margin-right: 8px;
    font-size: 1.3rem;
}

.playerCnt {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    .dropdown {
        font-size: 1.3rem;
        width: calc(100% - 48px);
        margin-right: 8px;
        &.viewDropdown {
            width: calc(100% - 12px);
        }    
    }
}

.hoverable {
    cursor: pointer;
    &:hover {
        color: $danger;
    }
}