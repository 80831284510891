@import '/src/scss/shared';

.loader {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .container {
        width: 300px;
        display: flex;
        text-align: center;
        flex-direction: column;

        .progressLineCnt {
            height: 5px;
            border: 1px solid $primary;

            .progressLine {
                background-color: $primary;
                height: 100%;
            }
        }
    }
}

