$primary: #56df93;
$onPrimary: #171717;

$secondary: #0c3e22;
$onSecondary: #fff;

$white: #fff;
$black: #000;

$dark: #b1b1b1;
$danger: #dc3545;
$success: $secondary;
$warning: rgb(255, 224, 0);

$barColor: $primary;
$landingBgColor: #0c3e22;

// KDK

/*$primary: rgb(144,224,170);
$onPrimary: rgb(20,49,27);

$secondary: rgb(20,49,27);
$onSecondary: #fff;

$white: #fff;
$black: rgb(20,49,27);

$dark: #b1b1b1;
$danger: #dc3545;
$success: $secondary;
$warning: $primary; */

/* Olympics */
//$primary: #d4c483;
//$onPrimary: #111111;

//$secondary: #0074b7;
//$onSecondary: #ffffff;

/* UPL official */
//$primary: #ff34a0;
//$onPrimary: yellow;

//$secondary: rgb(67, 25, 99);
//$onSecondary: #fff;