@import "/src/scss/shared";

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.row {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #eee;
  padding: 8px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  height: 54px;

  &:hover {
    background-color: #eee;
  }

  .bio {
    display: flex;
    font-size: 1rem;
    line-height: 1.5rem;
    align-items: center;
    margin-left: 18px;
    width: 138px;

    .photo {
      width: 35px;
      border-radius: 100%;
    }

    .lastname {
      font-weight: bold;
      text-transform: uppercase;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 90px;
    }

    .info {
      margin-left: 12px;
      position: relative;

      .hint {
        text-transform: uppercase;
      }
    }

    .icon {
      margin-left: 8px;
      color: $secondary;
      &.rotate {
        transform: rotate(0deg);
        animation: spin 3s linear infinite;
      }
    }

    .position {
      position: absolute;
      top: 0;
      left: -84px;
      top: 12px;
    }
  }
}

.blur {
  .column {
    filter: blur(4px);
  }
}

.clickDisabled {
  opacity: 0.2;
  pointer-events: none;
}

.toggled {
  background-color: lighten($primary, 30%);
}

.icon {
  font-size: 1.5rem;
  color: $secondary;
  color: $onSecondary;
}

.cap {
  position: absolute;
  bottom: 0;
  right: -12px;
  color: $onSecondary;
}

.column {
  flex: 0 1 55px;
  position: relative;
  height: 20px;
}

.caption {
  position: absolute;
  top: -39px;
  width: 67px;
  font-size: 1rem;
  line-height: 1rem;
  text-transform: uppercase;
  font-weight: bold;
  left: -19px;
  text-align: center;
}

.autoreplace {
  position: absolute;
  top: 1px;
  color: $danger;
  background-color: $white;
  border-radius: 100%;
  display: flex;
  font-size: 16px;
  left: -23px;
  &.up {
    color: green;
  }
  &.down {
    color: $danger;
  }
}

.pts {
  font-size: 1rem;
}
